<template>
  <v-container
    id="atualizacoes"
    fluid
    tag="section"
  >
    <v-row
      class="mb-7 fade-transition"
      justify="center"
      no-gutters
    >
      <v-col
        class="text-center"
        cols="12"
        md="11"
        lg="10"
      >
        <upload-files
          :local-dialog="getDialog"
          :bucket-name="bucketName"
          :tree="tree"
        />
        <v-row
          justify="center"
          no-gutters
        >
          <v-col
            cols="10"
            md="12"
            sm="12"
          >
            <base-material-card
              icon="mdi-puzzle"
              color="#009688"
              :loading="loading"
            >
              <v-row
                align="start"
                no-gutters
              >
                <v-spacer />
                <v-btn
                  v-if="!loading"
                  dark
                  color="#009688"
                  class="mr-4"
                  @click="upload()"
                >
                  Upload
                  <v-divider
                    class="mx-4"
                    vertical
                  />
                  <v-icon>
                    mdi-cloud-upload-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  text
                  color="#009688"
                  @click="loadingChanger()"
                >
                  Atualizar
                  <v-divider
                    class="mx-4"
                    vertical
                  />
                  <v-icon>mdi-update</v-icon>
                </v-btn>
              </v-row>
              <v-row align="center">
                <v-btn
                  v-if="!loading && isAdmin"
                  color="#009688"
                  class="ml-2"
                  dark
                  @click="bucketDialog = true"
                >
                  Mudar bucket
                  <v-divider
                    class="mx-4"
                    vertical
                  />
                  <v-icon>
                    mdi-pail-plus-outline
                  </v-icon>
                </v-btn>
                <v-text-field
                  v-model="searchText"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  class="px-3 py-3"
                  counter="60"
                  label="Buscar"
                />
              </v-row>
              <v-row
                justify="center"
              >
                <v-col
                  v-if="!loading"
                  cols="12"
                  md="6"
                  sm="12"
                >
                  <v-treeview
                    v-model="selection"
                    :items="files"
                    :open.sync="open"
                    :active.sync="selection"
                    :search="searchText"
                    item-key="path"
                    open-on-click
                    open-all
                    selected-color="#009688"
                    rounded
                    return-object
                    selectable
                    activatable
                    multiple-active
                  >
                    <template v-slot:prepend="{ item }">
                      <v-icon v-if="item.children">
                        {{ open.includes(item) ? 'mdi-folder-open' : 'mdi-folder' }}
                      </v-icon>
                      <v-icon v-else>
                        {{ item.icon }}
                      </v-icon>
                    </template>
                    <template v-slot:label="{ item }">
                      <div class="text-wrap text-start">
                        {{ item.name }}
                      </div>
                    </template>
                  </v-treeview>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-card
                    class="pt-6 mx-auto"
                    flat
                    max-width="400"
                  >
                    <v-row
                      v-if="selected"
                      style="height: 50%;"
                      class="px-3 py-3 text-center"
                      align="center"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                      >
                        <div
                          v-for="(file, index) in selected"
                          :key="index"
                        >
                          <br>
                          <br>
                          <h3>
                            <v-icon>
                              {{ file.icon }}
                            </v-icon>
                            {{ file.name }}
                            <br>
                            {{ tamanhoArquivo(file.size) }}
                            <br>
                          </h3>
                          {{ file.path }}
                          <br>
                          <v-btn
                            color="#009688"
                            @click="download(file)"
                          >
                            <v-icon>
                              mdi-cloud-download-outline
                            </v-icon>
                            <v-divider
                              class="mx-4"
                              vertical
                            />
                            Baixar
                            <v-divider
                              class="mx-4"
                              vertical
                            />
                            {{ tamanhoArquivo(file.size) }}
                          </v-btn>
                          <br>
                          <v-btn
                            color="#B71C1C"
                            @click="deleteConfirm(file)"
                          >
                            <v-icon>
                              mdi-delete-circle-outline
                            </v-icon>
                            <v-divider
                              class="mx-4"
                              vertical
                            />
                            Apagar
                          </v-btn>
                          <br>
                          <br>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      multi-line
      color="#0277BD"
      :timeout="timeout"
    >
      {{ snackbarText }}
      <v-progress-linear
        :value="downloadedPercent"
        stream
        rounded
        color="#4CAF50"
        height="25"
      >
        <template v-slot="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-sheet
          color="error"
        >
          <v-card-text>
            <v-alert
              prominent
              type="error"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="text-center font-weight-regular align-center mt-3 mb-3">
                    <b>Esta ação irá deletar o arquivo permanentemente. Tem certeza que deseja fazer isso?</b>
                  </h2>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click="dialog = false"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click="deletar(fileToDelete)"
                  >
                    Deletar
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
        </v-sheet>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import filesize from 'filesize'

  export default {
    name: 'FilesAWS',
    components: {
      UploadFiles: () => import('./components/atualizacoes/uploadFiles'),
    },
    data () {
      return {
        isAdmin: false,

        files: [],
        open: [],
        selection: [],
        tree: [],

        dialog: false,
        bucketDialog: false,
        fileToDelete: {},
        deleteConfirmation: false,

        downloadedSize: '',
        downloadedPercent: 0,

        loading: false,
        searchText: this.$route.query.s || '',
        snackbar: false,
        snackbarText: '',
        timeout: -1,
      }
    },
    computed: {
      ...mapGetters('atualizacoes', ['fileList', 'getDialog', 'bucketName']),
      selected () {
        if (this.selection[0]) {
          return this.selection
        } else {
          return false
        }
      },
      tamanhoTotal () {
        let sum = 0
        let i
        for (i in this.selected) {
          sum += this.selected[i].size
        }
        return sum
      },
    },
    watch: {
      getDialog: function (val) {
        if (!val) {
          setTimeout(() => this.loadingChanger(), 1000)
        }
      },
      deleteConfirmation: function (val) {
        if (val) {
          setTimeout(() => this.loadingChanger(), 1000)
        }
      },
    },

    async mounted () {
      await this.loadingChanger()
      this.tree = this.directoriesArranger(this.fileList)
    },
    methods: {
      ...mapActions('atualizacoes', ['listFiles', 'downloadFile', 'deleteFile', 'updateDialog']),

      async loadingChanger () {
        this.loading = true
        await this.listFiles()
        this.files = this.fileList
        this.selection = []
        this.loading = false
      },
      tamanhoArquivo (file) {
        return filesize(file)
      },
      async download (file) {
        console.log(file, 'Downloading...')

        this.downloadFile({ file: file.path })
      },
      upload () {
        this.updateDialog(!this.getDialog)
      },
      directoriesArranger (arr) {
        const arraySaida = []
        arr.map(a => {
          if (a.children) {
            const objeto = { children: [], name: '', path: '' }
            objeto.name = a.name
            objeto.path = a.path
            objeto.children = this.directoriesArranger(a.children)
            arraySaida.push(objeto)
          }
        })
        return arraySaida
      },
      async deletar (file) {
        this.deleteConfirmation = true
        await this.deleteFile({ file: file.path })
        this.dialog = false
        this.deleteConfirmation = false
        this.fileToDelete = {}
      },
      deleteConfirm (file) {
        this.dialog = true
        this.fileToDelete = file
      },
    },
  }
</script>
